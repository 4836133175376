import fetch from "auth/FetchInterceptor";

const getAllUsuarios = async () => {
  const result = await fetch({
    url: "/usuario",
    method: "get",
  });

  return result.data;
}

const getByIdUsuario = async (id) => {
  const result = await fetch({
    url: `/usuario/${id}`,
    method: "get",
  });

  return result.data;
}

const createUsuario = async (usuario) => {
  const result = await fetch({
    url: "/usuario",
    method: "post",
    data: usuario,
  });

  return result.data
}

const updateUsuario = async (id, data) => {
  const result = await fetch({
    url: `/usuario/${id}`,
    method: "patch",
    data
  });

  return result.data;
}

const updateProfile = async (id, data) => {
  const result = await fetch({
    url: `/usuario/perfil/${id}`,
    method: "patch",
    data
  });

  return result.data;
}

const deleteUsuario = async (id) => {
  const result = await fetch({
    url: `/usuario/${id}`,
    method: "delete",
  });

  return result.data;
}



const usuarioService = {
  getAllUsuarios,
  getByIdUsuario,
  createUsuario,
  updateUsuario,
  deleteUsuario,
  updateProfile
};

export default usuarioService;

