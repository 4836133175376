import authService from 'services/auth.service';
import { create } from 'zustand';
import { AUTH_TOKEN, IS_ADMIN, ROLE, USER_AVATAR, USER_ID, USER_LOGGED, USER_NAME } from '../constants/AuthConstant';
import { message } from 'antd';

export const useAuth = create(set => ({
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
  idUser: localStorage.getItem(USER_ID),
  isAdmin: localStorage.getItem(IS_ADMIN) === 'true',
  userName: localStorage.getItem(USER_NAME),
  avatar: localStorage.getItem(USER_AVATAR),
  role: localStorage.getItem(ROLE),

  showAuthMessage: (message) => {
    set({ message, showMessage: true, loading: false });
  },
  showLoading: () => {
    set({ loading: true });
  },
  hideAuthMessage: () => {
    set({ message: '', showMessage: false });
  },
  authenticated: (token) => {
    set({ loading: false, redirect: '/app/home', token: token });
  },
  updateProfile: (avatar, nome) => {
    set({ avatar, userName: nome });
    window.localStorage.setItem(USER_AVATAR
      , avatar);
    window.localStorage.setItem(USER_NAME, nome);

  },
  login: async (body) => {
    set({ loading: true, showMessage: false });

    try {
      const result = await authService.login(body);

      set({
        token: result.token,
        idUser: result.usuario.id,
        isAdmin: result.usuario.admin,
        userName: result.usuario.nome,
        avatar: result.usuario.avatar,
      });
      window.localStorage.setItem(AUTH_TOKEN, result.token);
      window.localStorage.setItem(USER_LOGGED, result.usuario.login);
      window.localStorage.setItem(USER_ID, result.usuario.id);
      window.localStorage.setItem(USER_NAME,
        result.usuario.nome);
      window.localStorage.setItem(IS_ADMIN,
        result.usuario.admin);
      window.localStorage.setItem(USER_AVATAR,
        result.usuario.avatar);

      return result;
    } catch (e) {
      set({ message: e.response?.data?.message, showMessage: true });
    } finally {
      set({
        loading: false,
      });
    }
  },
  forgotPassword: async (body) => {
    set({ loading: true, showMessage: false });
    try {
      const result = await authService.forgotPassword(body);
      set({ loading: false });
      return result;
    } catch (e) {
      set({ message: e?.response?.data?.message, showMessage: true });
    } finally {
      set({
        loading: false,
      });
    }
  },
  resetPassword: async (body) => {
    set({ loading: true, showMessage: false });
    try {
      const result = await authService.resetPassword(body);
      set({ loading: false });
      message.success('Senha redefinida com sucesso!');
      return result;
    } catch (e) {
      set({ message: e?.response?.data?.message, showMessage: true });
      message.error('Ocorreu um erro ao redefinir a senha! Tente com um link anterior ou tente novamente mais tarde.');
    } finally {
      set({
        loading: false,
      });
    }
  },
  logout: () => {
    set({
      token: null,
      idUser: null,
      isAdmin: null,
      userName: null
    });
    window.localStorage.removeItem(AUTH_TOKEN);
    window.localStorage.removeItem(USER_LOGGED);
    window.localStorage.removeItem(USER_ID);
    window.localStorage.removeItem(USER_NAME);
    window.localStorage.removeItem(IS_ADMIN);
    window.localStorage.removeItem(USER_AVATAR);

    window.location.href = '/auth/login';
  },
}));
