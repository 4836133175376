import {
  HomeOutlined,
  UserOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'home',
  icon: HomeOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'users',
  path: `${APP_PREFIX_PATH}/usuarios`,
  title: 'users',
  icon: UserOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'registers',
  title: 'registers',
  breadcrumb: false,
  icon: UsergroupAddOutlined,
  submenu: [
    {
      key: 'religious',
      path: `${APP_PREFIX_PATH}/religiosas`,
      title: 'religious',
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'provinces',
      path: `${APP_PREFIX_PATH}/provincias`,
      title: 'provinces',
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'fraternities',
      path: `${APP_PREFIX_PATH}/fraternidades`,
      title: 'fraternities',
      breadcrumb: false,
      submenu: []
    }
  ]
}
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
