import { DIR_LTR, NAV_TYPE_TOP, SIDE_NAV_LIGHT } from 'constants/ThemeConstant';

export const APP_NAME = 'CIFSJ';
export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_TOP,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};
