export const AUTH_TOKEN = "auth_token_cifsj";
export const REFRESH_TOKEN = "refresh_token_cifsj";
export const AUTH_STORAGE = "auth-storage-cifsj";
export const USER_ID = "user_id_cifsj";
export const USER = "user_cifsj";
export const USER_NAME = "user_name_cifsj";
export const ROLE = "role_cifsj";
export const USER_LOGGED = "user_logged_cifsj";
export const IS_ADMIN = "is_ad_cifsj";
export const USER_AVATAR = "user_avatar_cifsj";
