import { notification } from "antd";
import usuarioService from "services/usuario.service";
import { create } from "zustand";

export const useUsuario = create((set, get) => ({
  loading: false,
  usuarios: [],
  usuario: null,
  error: null,
  showMessage: false,

  getUsuarios: async () => {
    set({ loading: true, showMessage: false });

    try {
      const result = await usuarioService.getAllUsuarios();

      set({
        usuarios: result,
      });

      return result.data;
    } catch (e) {
      set({ message: e.response?.data?.message, showMessage: true });
    } finally {
      set({
        loading: false,
      });
    }
  },

  getUsuarioById: async (id) => {
    set({ loading: true, showMessage: false });

    try {
      const result = await usuarioService.getByIdUsuario(id);

      set({
        usuario: result,
      });

      return result;
    } catch (e) {
      set({ message: e.response?.data?.message, showMessage: true });
    } finally {
      set({
        loading: false,
      });
    }
  },

  createUsuario: async (usuario) => {
    set({ loading: true, showMessage: false });

    try {
      const result = await usuarioService.createUsuario(usuario);

      set({
        usuarios: [...get().usuarios, result],
      });

      return result;
    } catch (e) {

      set({ error: e?.response?.data?.message, showMessage: true });
      notification.error({
        message: "Ocorreu um erro",
        description: e?.response?.data?.message,
      });
    } finally {
      set({
        loading: false,
      });
    }
  },

  updateUsuario: async (id, data) => {
    set({ loading: true, showMessage: false });

    try {
      const result = await usuarioService.updateUsuario(id, data);

      let updatedUsuarios = get().usuarios.map((item) =>
        item.id !== id
          ? item
          : {
            id,
            ...data,
          }
      );

      set({
        usuarios: updatedUsuarios,
      });

      return result;
    } catch (e) {
      set({ message: e.response.data.message, showMessage: true });
    } finally {
      set({
        loading: false,
      });
    }
  },
  deleteUsuario: async (id) => {
    set({ loading: true, showMessage: false });

    try {
      const result = await usuarioService.deleteUsuario(id);

      let updatedUsuarios = get().usuarios.filter((item) => item.id !== id);

      set({
        usuarios: updatedUsuarios,
      });

      return result;
    } catch (e) {
      set({ message: e.response.data.message, showMessage: true });
    } finally {
      set({
        loading: false,
      });
    }
  },
}));
