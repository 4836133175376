import {
  EditOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { Avatar, Button, Dropdown, Menu } from "antd";
import Text from "antd/lib/typography/Text";
import Icon from 'components/util-components/Icon';
import { useState } from 'react';
import { useAuth } from 'stores/auth.store';
import ProfileEditModal from './ProfileEditModal';

const menuItem = [
  {
    title: "Editar Perfil",
    icon: EditOutlined,
    path: "/"
  },
]

export const NavProfile = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const { logout, userName, isAdmin, avatar } = useAuth();

  const profileImg = avatar ? avatar : "/img/avatars/default-img.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-body">
        <Menu>

          <Menu.Item onClick={() => setModalVisible(true)}>

            <Icon type={EditOutlined} />
            <span className="font-weight-normal">Editar Perfil</span>

          </Menu.Item>

          <Menu.Item key={menuItem.length + 1} onClick={e => logout()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sair da conta</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>


      <ProfileEditModal
        visible={modalVisible}
        cancel={() => setModalVisible(false)}
      />
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Button type="text" className=" w-100 h-100">
        <div className="d-flex align-items-center justify-content-center">
          <Avatar src={profileImg} />
          <div className="pl-3 d-flex flex-column align-items-start"
            style={{
              gap: "12px",
            }}>
            <h4 style={{
              lineHeight: "0",
              margin: !isAdmin && "0",
            }}>{userName}</h4>
            {isAdmin &&
              <Text
                type="secondary"
                style={{
                  lineHeight: "0",
                }}
              >
                Administrador
              </Text>
            }
          </div>
        </div>
      </Button>
    </Dropdown>
  );
}

export default NavProfile
