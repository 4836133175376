import { Avatar, Button, Col, Form, Input, message, Modal, Row } from "antd";
import { DeleteOutlined, CameraOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useAuth } from "stores/auth.store";
import { useUsuario } from "stores/usuario.store";
import usuarioService from "services/usuario.service";
import { resizeFile } from "helpers/file.helper";

const ProfileEditModal = ({ visible, cancel }) => {
  const [form] = Form.useForm();
  const [avatarUrl, setAvatarUrl] = useState(null);
  const { userName, idUser, updateProfile } = useAuth();
  const { getUsuarioById, usuario } = useUsuario();

  const onSubmit = (values) => {
    usuarioService
      .updateProfile(idUser, {
        nome: values.nome,
        avatar: avatarUrl ? avatarUrl : '',
      })
      .then(() => {
        message.success("Perfil atualizado com sucesso!");
        updateProfile(avatarUrl, values.nome);
        cancel();
      })
      .catch((error) => {
        console.log(error);
        message.error("Erro ao atualizar perfil.");
      });
  };

  const handleChangeAvatar = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const convertBase64 = await resizeFile(file);
      setAvatarUrl(convertBase64);
    }
  };

  const handleRemoveAvatar = () => {
    Modal.confirm({
      title: "Você tem certeza que deseja excluir esta foto?",
      okText: "Sim",
      cancelText: "Não",
      centered: true,
      onOk: () => setAvatarUrl(""),
    });
  };

  useEffect(() => {
    if (idUser) {
      getUsuarioById(idUser);
    }
  }, [idUser, visible]);

  useEffect(() => {
    form.setFieldsValue({
      nome: userName,
      email: usuario?.email,
      password: "",
    });
    setAvatarUrl(usuario?.avatar);
  }, [userName, visible, usuario]);

  return (
    <Modal
      title="Editar Perfil"
      visible={visible}
      destroyOnClose={true}
      onCancel={cancel}
      onOk={() => form.submit()}
      okText="Salvar"
      cancelText="Cancelar"
    >
      <Form
        form={form}
        layout="vertical"
        name="modalForm"
        preserve={false}
        onFinish={onSubmit}
      >
        <Row gutter="30">
          <Col xs={15} sm={15} md={15} lg={15}>
            <Row gutter="16">
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  name="nome"
                  label="Nome"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira um nome.",
                    },
                  ]}
                >
                  <Input placeholder="Ex: José Silva" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter="16">
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item name="email" label="Email">
                  <Input
                    placeholder="Ex: jose@gmail.com"
                    type="email"
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={9} sm={9} md={9} lg={9}>
            <Avatar
              size={128}
              shape="square"
              src={avatarUrl ? avatarUrl : "/img/avatars/default-img.png"}
              style={{
                width: "100%",
                height: "180px",
              }}
            />
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="avatarInput"
              onChange={handleChangeAvatar}
            />
            <Row gutter="16">
              <Col xs={16} sm={16} md={16} lg={16}>
                <Button
                  className="btn btn-primary w-100"
                  style={{
                    marginTop: "10px",
                  }}
                  icon={<CameraOutlined />}
                  type="default"
                  onClick={() => document.getElementById("avatarInput").click()}
                >
                  Alterar
                </Button>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8}>
                <Button
                  className="btn btn-primary w-100"
                  style={{
                    marginTop: "10px",
                  }}
                  icon={<DeleteOutlined />}
                  danger
                  onClick={handleRemoveAvatar}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProfileEditModal;
