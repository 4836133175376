import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import { notification } from 'antd';
import { AUTH_TOKEN } from 'constants/AuthConstant';

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login';
const TOKEN_PAYLOAD_KEY = 'authorization';
const PUBLIC_REQUEST_KEY = 'public-request';

// API Request interceptor
service.interceptors.request.use(
	config => {
		const jwtToken = localStorage.getItem(AUTH_TOKEN);
		// const jwtToken = true;
		if (jwtToken) {
			config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
		}

		if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
			history.push(ENTRY_ROUTE);
			window.location.reload();
		}

		return config;
	},
	error => {
		console.log('error', error)
		// Do something with request error here
		notification.error({
			message: 'Error',
		});
		Promise.reject(error);
	}
);

// API respone interceptor
service.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		return Promise.reject(error);
	}
);

export default service