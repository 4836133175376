import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Logo from "./Logo";
import NavPanel from "./NavPanel";
import NavSearch from "./NavSearch";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import { useTheme } from "stores/theme.store";
import navigationConfig from "configs/NavigationConfig";
import SubMenu from "antd/lib/menu/SubMenu";
import Icon from "components/util-components/Icon";
import IntlMessage from "components/util-components/IntlMessage";
import { Link } from 'react-router-dom';

const { Header } = Layout;

export const HeaderNav = ({ isMobile, localization = true, topNavColor }) => {
  const {
    navCollapsed,
    toggleCollapsedNav,
    mobileNav,
    navType,
    headerNavColor,
    onMobileNavToggle,
    currentTheme,
    direction,

  } = useTheme();

  const [searchActive, setSearchActive] = useState(false);

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} />

        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
              {!isMobile &&
                <Menu
                  mode="horizontal"
                  style={{
                    backgroundColor: topNavColor, lineHeight: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%'
                  }}
                >
                  {navigationConfig.map(menu =>
                    menu.submenu.length > 0 ? (
                      <SubMenu
                        key={menu.key}
                        popupClassName="top-nav-menu"
                        title={
                          <span>
                            {menu.icon ? <Icon type={menu?.icon} /> : null}
                            <span>{setLocale(localization, menu.title)}</span>
                          </span>
                        }
                      >
                        {menu.submenu.map(subMenuFirst =>
                          subMenuFirst.submenu.length > 0 ? (
                            <SubMenu
                              key={subMenuFirst.key}
                              popupClassName="top-nav-menu"
                              icon={
                                subMenuFirst.icon ? (
                                  <Icon type={subMenuFirst?.icon} />
                                ) : null
                              }
                              title={setLocale(localization, subMenuFirst.title)}
                            >
                              {subMenuFirst.submenu.map(subMenuSecond => (
                                <Menu.Item key={subMenuSecond.key}>
                                  <span>
                                    {setLocale(localization, subMenuSecond.title)}
                                  </span>
                                  <Link to={subMenuSecond.path} />
                                </Menu.Item>
                              ))}
                            </SubMenu>
                          ) : (
                            <Menu.Item key={subMenuFirst.key}
                            >
                              {subMenuFirst.icon ? (
                                <Icon type={subMenuFirst?.icon} />
                              ) : null}
                              <span>{setLocale(localization, subMenuFirst.title)}</span>
                              <Link to={subMenuFirst.path} />
                            </Menu.Item>
                          )
                        )}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={menu.key}
                        style={{ lineHeight: '0px' }}
                      >
                        {menu.icon ? <Icon type={menu?.icon} /> : null}
                        <span>{setLocale(localization, menu?.title)}</span>
                        {menu.path ? <Link to={menu.path} /> : null}
                      </Menu.Item>
                    )
                  )}
                </Menu>}
            </ul>
          </div>
          <div className="nav-right">
            <NavPanel direction={direction} />
          </div>

          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
};

export default HeaderNav;
