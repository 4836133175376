import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useHistory } from "react-router-dom";
import { useAuth } from "stores/auth.store";

export const AppViews = () => {

  const history = useHistory()
  const { token } = useAuth()

  useEffect(() => {
    if (!token) {
      history.push('/auth/login')
    }
  }, [token, history])


  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} exact />
        <Route path={`${APP_PREFIX_PATH}/usuarios`} component={lazy(() => import(`./users`))} exact />
        <Route path={`${APP_PREFIX_PATH}/religiosas`} component={lazy(() => import(`./religious`))} exact />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/religiosas/add/:id`}
          component={lazy(() => import("./religious/add"))}
        />
        <Route path={`${APP_PREFIX_PATH}/religiosas/add`} component={lazy(() => import(`./religious/add`))} exact />

        <Route path={`${APP_PREFIX_PATH}/provincias`} component={lazy(() => import(`./provinces`))} exact />
        <Route path={`${APP_PREFIX_PATH}/fraternidades`} component={lazy(() => import(`./fraternities`))} exact />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);